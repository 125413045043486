<template>
  <div class="layout-main p-3 flex">
    <div class="layout-main-sidebar h-full shadow bg-white hidden md:flex mr-3" :style="`width: ${isCollapse ? '72px' : '200px'}`">
      <el-scrollbar>
        <el-menu :collapse-transition="false" default-active="/dashboard/index" :style="`border:0;width: ${isCollapse ? '72px' : '200px'}`" :collapse="isCollapse">
          <router-link to="/dashboard/index">
            <el-menu-item index="1">
              <el-icon>
                <home-filled />
              </el-icon>
              <template #title>首页</template>
            </el-menu-item>
          </router-link>

          <template v-for="item of menuList" :key="item.id">
            <router-link :to="item.url" v-if="item.menuFlag == 'N'">
              <el-menu-item :index="item.url">
                <el-icon v-html="item.icon"></el-icon>
                <template #title>{{ item.name }}</template>
              </el-menu-item>
            </router-link>
            <el-sub-menu :index="item.url" v-if="item.menuFlag == 'Y'">
              <template #title>
                <el-icon v-html="item.icon"></el-icon>
                <span>{{ item.name }}</span>
              </template>
              <router-link :to="childItem.url" v-for="childItem of item.menus" >
                <el-menu-item :index="childItem.url" v-if="childItem">
                  <el-icon v-html="childItem.icon"></el-icon>
                  {{
                    childItem.name
                  }}
                </el-menu-item>
              </router-link>
            </el-sub-menu>
          </template>
        </el-menu>
      </el-scrollbar>
    </div>

    <el-drawer title="SideBar" size="200" v-model="isShowDrawer" direction="ltr" :with-header="false">
      <el-scrollbar>
        <el-menu :collapse-transition="false" default-active="/dashboard/index" style="width: 200px;" @select="menuSelectedInDrawer">
          <router-link to="/dashboard/index">
            <el-menu-item index="1">
              <el-icon>
                <home-filled />
              </el-icon>
              <template #title>首页</template>
            </el-menu-item>
          </router-link>

          <template v-for="item of menuList" :key="item.id">
            <router-link :to="item.url"  v-if="item.menuFlag == 'N'">
              <el-menu-item :index="item.url">
                <el-icon v-html="item.icon"></el-icon>
                <template #title>{{ item.name }}</template>
              </el-menu-item>
            </router-link>
            <el-sub-menu :index="item.url" v-if="item.menuFlag == 'Y'">
              <template #title>
                <el-icon v-html="item.icon"></el-icon>
                <span>{{ item.name }}</span>
              </template>
              <router-link  :to="childItem.url" v-for="childItem of item.menus" >
                <el-menu-item :index="childItem.url" v-if="childItem">
                  {{
                    childItem.name
                  }}
                </el-menu-item>
              </router-link>
            </el-sub-menu>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-drawer>
    <el-scrollbar class="flex-1 layout-main-content">
      <div class="flex-1 shadow bg-white p-3">
        <router-view v-slot="{ Component }" >
          <keep-alive   >
            <component :is="Component" v-if="$route.meta&&$route.meta.keepAlive" :key="$route.name"/>
          </keep-alive>
          <component :is="Component" v-if="!($route.meta&&$route.meta.keepAlive)" :key="$route.name"/>
        </router-view>
      </div>
    </el-scrollbar>
    <div></div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { onMounted } from "vue";
import { HomeFilled, Tools } from '@element-plus/icons-vue'
// import { ElScrollbar,ElMenu,ElMenuItem,ElSubmenu,ElMenuItemGroup,ElDrawer } from "element-plus";
import MittUtils from "../../utils/MittUtils";
import { useMenuStore } from "../../store/menuStore";
const mainMenu = useMenuStore();
//定义菜单
const menuList = ref([]);
const isCollapse = ref(false);
const isShowDrawer = ref(false);
const mobileOffSetWidth = ref(1024);

const menuSelectedInDrawer = () => {
  isShowDrawer.value = false;
}
MittUtils.on("isCollapse", (e: boolean) => {
  isCollapse.value = e;
  if (mobileOffSetWidth.value < 768) {
    isShowDrawer.value = true;
  }
});
//订阅store状态的变化，类似 watch
mainMenu.$subscribe((mutation, state) => {
  console.log("----------$subscribe------------", state.menus);
  //@ts-ignore
  menuList.value = state.menus;
});
onMounted(() => {
  //初始化菜单
  menuList.value = mainMenu.menus;
  // console.log(JSON.stringify(menuList.value))
  mobileOffSetWidth.value = document.body.offsetWidth;
  console.log("mounted mobileOffSetWidth:" + mobileOffSetWidth.value);
  //监听页面宽度变化
  window.addEventListener("resize", () => {
    console.log("document.body.offsetWidth:" + document.body.offsetWidth);
    setTimeout(() => {
      mobileOffSetWidth.value = document.body.offsetWidth;
    }, 300);
  });
});
</script>
<style lang="postcss" scoped>
.layout-main-sidebar {
  height: calc(100vh - 80px);
  transition: width 0.35s;
}
.layout-main-content {
  height: calc(100vh - 66px);
}
</style>

<style lang="postcss">
li.el-menu-item {
  font-size: 15px;
  font-weight: bold;
  color: #555;
}
.el-sub-menu__title {
  /* font-size: 15px !important; */
  font-weight: bold;
  color: #555;
}
li.el-sub-menu li.el-menu-item {
  color: gray;
  padding-left: 30px !important;
}
</style>
