import { createApp } from 'vue'
import App from './App.vue';
import { createPinia } from 'pinia'
import router from './router/router';
import 'virtual:svg-icons-register';
import 'virtual:windi.css'
import MySvgIcoon from "./components/base/MySvgIcon.vue";
import permission from './directives/permission';
import 'md-editor-v3/lib/style.css';
import './assets/styles/index.scss';

const app = createApp(App)
app.component('MySvgIcon', MySvgIcoon)

//@ts-ignore
app.use(router)
//@ts-ignore
app.use(createPinia())

//注册自定义指令
app.use(permission)

//@ts-ignore
// app.use(Editor)
app.mount("#app")
