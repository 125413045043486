<template>
  <!-- 参考： https://github.com/davidgrzyb/tailwind-login-template -->
  <div class="w-full flex flex-wrap justify-center ">
    <!-- Image Section -->
    <div class="w-1/2 shadow-2xl flex bg-gray-100" v-if="false">
      <div class="object-cover  w-full h-screen hidden md:flex justify-center items-center flex-col">
        <img class="block w-52 h-52" src="../assets/images/home-logo.png" alt="扫描二维码">
        <h2 class="my-4">扫码二维码，关注公众号</h2>
        <p>回复【 <span class="font-bold">帮助</span> 】获取账号及技术支持</p>
      </div>
    </div>

    <!-- Login Section -->
    <div class="w-full md:w-1/2 flex flex-col mt-1/10">


      <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
        <p class="text-center text-3xl">欢迎使用YiMuCao</p>
        <form class="flex flex-col pt-3 md:pt-8" onsubmit="event.preventDefault();">
          <div class="flex flex-col pt-4">
            <label for="email" class="text-lg">账号</label>
            <input type="email" v-model="form.username" placeholder="用户名"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline">
          </div>

          <div class="flex flex-col pt-4">
            <label for="password" class="text-lg">密码</label>
            <input type="password" v-model="form.password" placeholder="密码"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="flex flex-col pt-4" v-if="false">
            <label for="password" class="text-lg">图片验证码</label>
            <div class="flex items-center">
              <input type="text" v-model="form.captcha" placeholder="Captcha"
                class="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline">
              <img class="w-1/2 inline-block mx-10 cursor-pointer" :src="form.captchaUrl"
                style="width: 130px;height: 36px;" @click="getCaptcha()" />
            </div>
          </div>

          <input type="button" @click="doLogin" value="登 录"
            class="bg-blue-500 text-white font-bold text-lg hover:bg-blue-400 p-2 mt-8 cursor-pointer">
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AuthService from '../apis/AuthService'
import SysMenuService from '../apis/SysMenuService';
import { GlobalConsts } from '../config'
import CommonUtils from '../utils/CommonUtils'
import { useMenuStore } from "../store/menuStore";
import Md5 from '../utils/Md5Util';
const router = useRouter()
const mainMenu = useMenuStore();
// const userStore = useUserStore()
let form = reactive({
  username: '',
  password: '',
  // captcha: '',
  // captchaUrl: '',
  // captchaKey: ''
})
const getCaptcha = () => {
  // AuthService.getCaptcha().then(res => {
  //   if (resp.code == 200) {
  //     form.captchaUrl = res.data.captchaUrl;
  //     form.captchaKey = res.data.captchaKey
  //   }
  // })
}
onMounted(() => {
  getCaptcha()
})
const doLogin = () => {
  //doLoginTest()

  // return;
  //router.push("/")
  if (!form.username || !form.password ) {
    ElMessage.error("请填写用户名、密码");
    return;
  }
  AuthService.login({
    phone: form.username,
    pwd: Md5.hashStr(form.password),
    // captcha: form.captcha,
    // captchaKey: form.captchaKey
  }).then(async(res) => {
    if (res.code == 200) {
      // success data { token,username }
      CommonUtils.setLocalStore(GlobalConsts.TokenKey, res.data.token);
      CommonUtils.setLocalStore(GlobalConsts.CurrentLoginUserName, res.data.gopUser.name);
      CommonUtils.setLocalStore(GlobalConsts.CurrentLoginUserNo, res.data.gopUser.userNo);
      CommonUtils.setLocalStore(GlobalConsts.CurrentLoginUserRoleId, res.data.gopUser.roleId);
      //CommonUtils.setLocalStore(GlobalConsts.MenuUrl, JSON.stringify(res.data.menuUrl));
      const resp = await SysMenuService.getMenuTreeByRoleId(res.data.gopUser.roleId)
      if(resp.code == 200 && resp.data.length>0){
        console.log(resp)
        CommonUtils.setLocalStore(GlobalConsts.CurrentMenuTreeList,JSON.stringify(resp.data));
        setTimeout(async()=>{
          await mainMenu.setMenus()
          ElMessage.success("登录成功")
          router.push("/")
        })
      }else {
        ElMessage.error("登录权限错误");
      }
      
    } else {
      ElMessage.error(res.message);
    }
  })
}

function doLoginTest(){
  CommonUtils.setLocalStore(GlobalConsts.TokenKey, "res.data.token");
  CommonUtils.setLocalStore(GlobalConsts.CurrentLoginUserName,"res.data.username");
  //userStore.setUsername(res.data.username)
  ElMessage.success("登录成功")
  router.push("/")
}
</script>
