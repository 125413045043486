import { storageFactory } from "./LocalstorageFactory";
import { GlobalConsts } from '../config';

const local = storageFactory(() => localStorage);
//const session = storageFactory(() => sessionStorage);

function formatTime(time: string) {
  return time.substring(0, 16)
}



//IE10 以上支持
function Base64Encode(str: string) {
  console.log(str);
  return btoa(encodeURIComponent(str));
}
//IE10 以上支持
function Base64Decode(str: string) {
  return decodeURIComponent(atob(str));
}

function setLocalStore(key: string, value: string) {
  local.setItem(key, value)
}

function getLocalStore(key: string) {
  return local.getItem(key);
}
function clearLocalStore() {
  local.clear();
}

function isLogin() {
  if (getLocalStore(GlobalConsts.TokenKey)) {
    return true;
  }
  return false;
}


function getQiniuUploadUrl(data: {domain:string,key:string}){
  if(data&&data.domain&&data.key){
    return data.domain + "/" + data.key  
  }else {
    return ""
  }
}


const CommonUtils = {
  formatTime,
  setLocalStore,
  getLocalStore,
  clearLocalStore,
  isLogin,
  Base64Encode,
  Base64Decode,
  getQiniuUploadUrl
};


export default CommonUtils;
