import { defineStore } from 'pinia'
import SysMenuService from '../apis/SysMenuService';
import { GlobalConsts } from '../config/index';
import CommonUtils from '../utils/CommonUtils';

export const useMenuStore = defineStore('mainMenu', {
  state: () => ({
    menus: [],
    //主要是按钮权限和接口权限，当前项目不需要，为空即可
    permissions:[]
  }),
  actions: {
    async setMenus() {
      //@ts-ignore
      const menuTreeList = CommonUtils.getLocalStore(GlobalConsts.CurrentMenuTreeList);
      console.log("--------------------setMenus")
      if(menuTreeList){
        //@ts-ignore
        this.menus = JSON.parse(menuTreeList);
      }else{
        const resp = await SysMenuService.getMenuTreeByRoleId(GlobalConsts.CurrentLoginUserRoleId);
        if (resp.code == 200) {
          CommonUtils.setLocalStore(GlobalConsts.CurrentMenuTreeList,JSON.stringify(resp.data));
          this.menus = resp.data;
        }
      }
    }
  }
})
