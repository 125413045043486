import HttpUtils from '../utils/HttpUtils'

export default {
  // 获取所有菜单列表
  getList() {
    return HttpUtils.post("/gop/menu/menuTreeList.do")
  },
  /**
   * 获取当前登录用户的菜单列表，不需要传递参数，token即可
   * @returns 
   */
  getListByUser() {
    return HttpUtils.get("/admin/sys/menu/listByUser")
  },

  /**
 * 获取当前登录用户的菜单列表，需要传递roleId，roleId其实不需要传递，后端有bug
 * @returns 
 */
  getMenuTreeByRoleId(roleId:string) {
    return HttpUtils.get("/gop/menu/menuTreeListByRoleId.do?roleId=" + roleId)
  },

  //{"pmenuNo":"","name":"测试","description":"测试","icon":"","menuFlag":"N","menuNo":"/testtest","sort":1}
  addMenu(data: {
    name: string,
    menuNo: string,
    menuFlag: string,
    sort: number | string,
    icon: string,
    description: string
  }) {
    return HttpUtils.post("/gop/menu/add.do", data)
  },
  updateMenu(data: {
    id: string,
    name: string,
    menuNo: string,
    pmenuNo?: string,
    menuFlag: string,
    sort: number | string,
    icon: string,
    description: string
  }) {
    return HttpUtils.post("/gop/menu/edit.do", data)
  },
  getUserInfo() {
    return HttpUtils.get("/admin/sys/user/info")
  },
  getUserInfoById(userId: string) {
    return HttpUtils.get("/admin/sys/user/info/" + userId)
  },
  getUserInfoByPage(data: {
    pageNum: number,
    pageSize: number,
    username?: string
  }) {
    return HttpUtils.post("/admin/sys/user/list",data)
  },
  deleteById(menuId: string) {
    return HttpUtils.get(`/gop/menu/remove.do?id=${menuId}`)
  },
  getParentIdListById(id: string){
    return HttpUtils.get(`/admin/sys/menu/getParentIdListById?id=${id}`)
  }
}

