<template>
<div class="layout-header flex shadow bg-white">
  <div class="w200  items-center justify-start h-full text-blue-500 p-3 hidden md:flex font-bold font-mono">
    Welcome Yimucao
  </div>
  <div class="flex flex-1 items-center">
    <div class="flex justify-start items-center pl-3">
        <my-svg-icon icon-name="menu-fold"  class=" cursor-pointer" style="height: 30px;width: 30px;color: #555;" v-if="!isCollapse" @click="toggleCollapse"></my-svg-icon>
        <my-svg-icon icon-name="menu-unfold"  class=" cursor-pointer" style="height: 30px;width: 30px;color: #555;" v-else @click="toggleCollapse"></my-svg-icon>
    </div>
  </div>

  <div class='flex header-ucenter flex-row-reverse items-center px-6 min-width-32'>
      <!-- 用户下拉 -->
      <el-dropdown>
          <span class='flex px-2 el-dropdown-link flex-center items-center'>
              <el-avatar :size='30' src='https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' />
              <span class='ml-2 flex'> {{username}} </span>
          </span>
          <template #dropdown>
              <el-dropdown-menu>
                  <el-dropdown-item v-if="false">
                      <!-- <el-link href='#'  target='_blank' :underline='false'>个人中心</el-link> -->
                      <router-link to="/setting/profile">个人中心</router-link>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <router-link to="/help/index">帮助中心</router-link>
                  </el-dropdown-item>
                  <el-dropdown-item divided @click="doLogout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
          </template>
      </el-dropdown>
  </div>
</div>
</template>
<script lang="ts" setup>
import MittUtils from "../../utils/MittUtils";
// import { useUserStore } from "../../store/userStore";
import CommonUtils from "../../utils/CommonUtils";
import { GlobalConsts } from "../../config";
const router = useRouter()
// const userStore = useUserStore()
const isCollapse = ref(false)
const username = ref('')
const toggleCollapse = ()=>{
  isCollapse.value = !isCollapse.value;
  MittUtils.emit('isCollapse',isCollapse.value);
}
onMounted(()=>{
  if(!CommonUtils.isLogin()){
    //未登录
    router.push("/login")
  }else {
    // if(userStore.username){
    //   username.value = userStore.username;
    // }else {
    username.value = CommonUtils.getLocalStore(GlobalConsts.CurrentLoginUserName) as string;
    //}
  }

})

const doLogout = ()=>{
  CommonUtils.clearLocalStore();
  router.push("/login")
}
</script>
<style lang='postcss' scoped>
    .layout-header {
      height: 52px;
    }
</style>
