import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CommonUtils from '../utils/CommonUtils';
import DashboardIndex from '../views/dashboard/Index.vue'
import LayoutIndex from '../views/layout/Index.vue'
import Login from "../views/Login.vue";
import { useMenuStore } from '../store/menuStore'


/**
 * 白名单路由，不需要任何权限的路由
 */
const whiteList = [
  "/dashboard/index",
  "/sys/rbac/user",
  "sys/rbac/role",
  "sys/rbac/menu",
  "/dashboard/mymemo",
  "/setting/profile",
  "/help/index",
  "/article/addOrEdit",
  "/article/appDetail",
  "/nbstudy/osps/chapter/add",
  "/audit/dynamicInfoAuditDetail",
  "/audit/reportDetail",
  "/play/video/list",
  "/play/video/addOrEdit",
  "/play/video/detail",
  "/answerQuestion/addOrEdit",
  ///audit/dynamicInfoAuditDetail
  '/dynamicInfo/auditDetail',
  '/user/serviceChatDetail',
  '/banners/item/add'
];

//静态路由页面
export const staticRouter = [
  {
    name: "LayoutIndex",
    path: "/",
    component: LayoutIndex,
    redirect: "/dashboard/index",
    children: [
      {
        name: "DashboardIndex",
        path: "dashboard/index",
        component: DashboardIndex,
      },
      {
        name: "Setting",
        path: "setting/profile",
        component: () => import("../views/setting/Profile.vue"),
      },
      {
        name: "HelpIndex",
        path: "help/index",
        component: () => import("../views/help/Index.vue"),
      },
      {
        name: "Page401",
        path: "dashboard/401",
        component: () => import("../views/Page401.vue"),
      },
    ],
  },

  {
    name: "SysRbac",
    path: "/sys/rbac",
    component: LayoutIndex,
    children: [
      {
        name: "SysUser",
        path: "user",
        component: () => import("../views/sys/rbac/SysUser.vue"),
      },
      {
        name: "SysRole",
        path: "role",
        component: () => import("../views/sys/rbac/SysRole.vue"),
      },
      {
        name: "SysMenu",
        path: "menu",
        component: () => import("../views/sys/rbac/SysMenu.vue"),
      },
    ],
  },
  // 用户管理（APP注册用户）
  {
    name: "User",
    path: "/user",
    component: LayoutIndex,
    children: [
      {
        name: "UserList",
        path: "list",
        component: () => import("../views/user/UserList.vue"),
      },
      {
        name: "FeedbackList",
        path: "feedback",
        component: () => import("../views/user/FeedbackList.vue"),
      },
      {
        name: "ServiceChatList",
        path: "serviceChat",
        component: () => import("../views/user/ServiceChatList.vue"),
      },
      {
        name: "ServiceChatDetail",
        path: "serviceChatDetail",
        component: () => import("../views/user/ServiceChatDetail.vue"),
      },
      {
        name: "UserStatistics",
        path: "statistics",
        component: () => import("../views/user/UserStatistics.vue"),
      },
    ],
  },
  // 标签管理
  {
    name: "Tags",
    path: "/tag",
    component: LayoutIndex,
    children: [
      {
        name: "TagList",
        path: "list",
        component: () => import("../views/tags/TagList.vue"),
      },
    ],
  },
  // 话题管理
  {
    name: "Topic",
    path: "/topic",
    component: LayoutIndex,
    children: [
      {
        name: "TopicList",
        path: "list",
        component: () => import("../views/topic/TopicList.vue"),
      },
    ],
  },
  //专题管理
  {
    name: "Special",
    path: "/special",
    component: LayoutIndex,
    children: [
      {
        name: "SpecialList",
        path: "allList",
        component: () => import("../views/special/SpecialList.vue"),
      },
      {
        name: "SpecialAricleList",
        path: "articles",
        component: () => import("../views/special/SpecailArticleList.vue"),
      },
    ],
  },
  // 文章管理
  {
    name: "Article",
    path: "/article",
    component: LayoutIndex,
    children: [
      {
        name: "ArticleList",
        path: "list",
        component: () => import("../views/article/ArticleList.vue"),
      },
      {
        name: "ArticleChannelList",
        path: "channel/list",
        component: () => import("../views/article/ChannelList.vue"),
      },
      {
        name: "ArticleAdd",
        path: "addOrEdit",
        component: () => import("../views/article/ArticleAdd.vue"),
      },
      {
        name: "AppArticleDetail",
        path: "appDetail",
        component: () => import("../views/article/AppArticleDetail.vue"),
      },
    ],
  },
  // Banner管理
  {
    name: "Banners",
    path: "/banners",
    component: LayoutIndex,
    children: [
      {
        name: "BannersAll",
        path: "all",
        component: () => import("../views/banners/BannerList.vue"),
      },
      {
        name: "BannersArea",
        path: "area",
        component: () => import("../views/banners/BannerArea.vue"),
      },
      {
        name: "BannersAdd",
        path: "item/add",
        component: () => import("../views/banners/BannerAdd.vue"),
      },
    ],
  },
  // 问诊答题
  {
    name: "AnswerQuestion",
    path: "/answerQuestion",
    component: LayoutIndex,
    children: [
      {
        name: "Auestions",
        path: "questions",
        component: () => import("../views/answerQuestion/QuestionList.vue"),
      },
      {
        name: "AnswerList",
        path: "answerList",
        component: () => import("../views/answerQuestion/AnswerList.vue"),
      },
      {
        name: "QuestionsTag",
        path: "tags",
        component: () => import("../views/answerQuestion/QuestionTag.vue"),
      },
      {
        name: "QuestionAdd",
        path: "addOrEdit",
        component: () => import("../views/answerQuestion/QuestionAdd.vue"),
      },
    ],
  },
  // 视频管理
  {
    name: "Video",
    path: "/play/video",
    component: LayoutIndex,
    children: [
      {
        name: "VideoList",
        path: "list",
        component: () => import("../views/video/VideoList.vue"),
        meta: {
          keepAlive: true
        }
      },
      {
        name: "VideoAdd",
        path: "addOrEdit",
        component: () => import("../views/video/VideoAdd.vue"),
      },
      {
        name: "VideoDetail",
        path: "detail",
        component: () => import("../views/video/VideoDetail.vue"),
      },
    ],
  },
  //动态管理
  {
    name: "DynamicInfo",
    path: "/dynamicInfo",
    component: LayoutIndex,
    children: [
      {
        name: "AuditList",
        path: "auditList",
        component: () => import("../views/dynamicInfo/DynamicInfoList.vue"),
        meta: {
          keepAlive: true
        }
      },
      {
        name: "AuditDetail",
        path: "auditDetail",
        component: () => import("../views/dynamicInfo/AppDetail.vue"),
      },
    ],
  },
  // 审核管理
  {
    name: "Audit",
    path: "/audit",
    component: LayoutIndex,
    children: [
      {
        name: "ReportAudit",
        path: "reportAudit",
        component: () => import("../views/audit/ReportList.vue"),
      },
      {
        name: "ReportDetail",
        path: "reportDetail",
        component: () => import("../views/audit/ReportDetail.vue"),
      },
      {
        name: "CommentAudit",
        path: "commentAudit",
        component: () => import("../views/audit/CommentAudit.vue"),
      }
      // {
      //   name: "VideoAudit",
      //   path: "videoAudit",
      //   component: () => import("../views/audit/VideoAuditList.vue"),
      // },

      // {
      //   name: "ArticleAudit",
      //   path: "articleAudit",
      //   component: () => import("../views/audit/ArticleAudit.vue"),
      // },
    ],
  },

  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: staticRouter as RouteRecordRaw[]
})

//全局前置守卫, 路由权限与后台返回权限校验
router.beforeEach(async (to, from) => {
  const toPath = to.path;
  const mainMenu = useMenuStore();
  if (toPath == "/login" && CommonUtils.isLogin()) {
    return "/dashboard/index"
  } else if (toPath == "/login" && !CommonUtils.isLogin()) {
    return true;
  } else if (CommonUtils.isLogin()) {
    let menus = mainMenu.menus;
    console.log(menus)
    if (menus.length == 0) {
      //console.log("-----------------当前没有菜单，设置菜单后检查权限------------------", toPath)
      //当前没有菜单，设置菜单
      await mainMenu.setMenus();
      console.log(mainMenu.menus)
      //检查当前访问路由是否有权限
      //mainMenu.setMenus(); 是异步的
      const isTrue = validHasPathPermission(mainMenu.menus, toPath);
      console.log("-----------------当前没有菜单，设置菜单后检查权限--------after:",isTrue)
      if (isTrue || toPath == "/dashboard/401") {
        return true;
      } else {
        return "/dashboard/401"
      }
    } else {
      //console.log("-----------------当前有菜单,检查权限------------------", menus, toPath)
      //检查当前访问路由是否有权限
      const isTrue = validHasPathPermission(menus, toPath);
      console.log("-----------------当前有菜单,检查权限--------after:",isTrue)
      if (isTrue || toPath == "/dashboard/401") {
        return true;
      } else {
        return "/dashboard/401"
      }
    }
  } else {
    return "/login"
  }
})



//@ts-ignore
const validHasPathPermission = (menus, toPath): boolean => {
  //console.log("------validHasPathPermission-----------", menus, toPath)
  let isTrue = false;
  if (whiteList.includes(toPath)) {
    isTrue = true;
  } else {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].url == toPath) {
        isTrue = true;
        break;
      } else {
        let arr = menus[i].menus;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].url == toPath) {
            isTrue = true;
            break;
          }
        }
      }
    }
  }
  return isTrue;
}
export default router

