const TokenKey = "yimicao-token";
const CurrentLoginUserName = "yimucao-sys-username";
const CurrentLoginUserNo = "yimucao-sys-userno";
const MenuUrl = "yimucao-menu-url";
const CurrentLoginUserRoleId = "yimucao-curUser-roleId";
const CurrentMenuTreeList = "yimucao-menu-tree-list";
const TokenKeyToBackend = "Authorization";

export const GlobalConsts = {
  DevBaseUrl: '/yimucaoapi',
  ProdBaseUrl: '/yimucaoapi',
  TokenKey,
  CurrentLoginUserName,
  CurrentLoginUserNo,
  CurrentLoginUserRoleId,
  TokenKeyToBackend,
  MenuUrl,
  CurrentMenuTreeList,
  //1-待审核，2-已发布，3-草稿，5 隐藏，6 已拒绝
  DynamicInfoStatusList: [
    {
      id: 1,
      lable: '待审核',
    },{
      id: 2,
      lable: '已发布',
    },{
      id: 3,
      lable: '草稿',
    },{
      id: 5,
      lable: '隐藏',
    },{
      id: 6,
      lable: '已拒绝',
    }
  ],
  //1-待审核，2- 通过（上架），3-草稿，4-定时发布，5-拒绝，6-下架
  CommonStatusList: [
    {
      id: 1,
      lable: '待审核',
    },{
      id: 2,
      lable: '已发布',
    },{
      id: 3,
      lable: '草稿',
    },{
      id: 5,
      lable: '拒绝',
    },{
      id: 6,
      lable: '下架',
    }
  ],
  CommentTypeList: [
    {
      id: 1,
      lable: '文章',
    },{
      id: 2,
      lable: '视频',
    },{
      id: 3,
      lable: '动态',
    }
  ],
  //1-待审核，2-已发布，3-草稿，5 隐藏，6 已拒绝

  highlightjs:'https://qiniufiles.itshizhan.com/md_editor_assests/highlight.min.js',
  mermaidjs:'https://qiniufiles.itshizhan.com/md_editor_assests/mermaid.min.js',
  katexjs:'https://qiniufiles.itshizhan.com/md_editor_assests/katex.min.css',
  katexcss:'https://qiniufiles.itshizhan.com/md_editor_assests/katex.min.css',
  atom_one_dark_css:'https://qiniufiles.itshizhan.com/md_editor_assests/atom-one-dark.css'
}
